
import { defineComponent } from 'vue';
import { IonButton, IonPage } from '@ionic/vue';
export default defineComponent({
  name: 'AtsiriMuseum',
  components: {
      IonButton,
      IonPage
  },
  
});
