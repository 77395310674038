<template>
    <ion-page>
        <div id="container-museum-schedule" >
            <div v-if="isLoading" align="center" style="margin-top: 10%;">
                <ion-spinner name="circles" type="primary" style="color: #5a2e00;"></ion-spinner><br>
                <small style="color: #5a2e00;">Please wait, your request is being processed</small>
            </div>
            <div v-if="page == 'schedule' && !isLoading">
                <div>
                    <ion-label position="stacked" style="color: #5a2e00; font-weight: bold;">Date</ion-label>
                    <ion-input color="dark" type="date" v-model="payload.date" :min="minDate" />
                </div>
                <div style="margin-top: 10px;">
                    <ion-label style="color: #5a2e00; font-weight: bold;">Number of visitors</ion-label>
                    <ion-select :disabled="!isDateSelected" v-model="payload.qty" ok-text="Select" cancel-text="Close" placeholder="Select number">
                        <ion-select-option value="1">1 Visitor</ion-select-option>
                        <ion-select-option value="2">2 Visitors</ion-select-option>
                        <ion-select-option value="3">3 Visitors</ion-select-option>
                        <ion-select-option value="4">4 Visitors</ion-select-option>
                        <!-- <ion-select-option value="5">5 Visitors</ion-select-option> -->
                    </ion-select>
                </div>
                

                <div v-if="isTimeLoading" align="center" style="margin-top: 20px;">
                    <ion-spinner name="circles" type="primary" style="color: #5a2e00;"></ion-spinner>
                </div>

                <div style="margin-top: 10px;" v-if="tableOption && tableOption.length && timeLoaded && !isTimeLoading">
                    <ion-label style="color: #5a2e00; font-weight: bold;">Table</ion-label>
                    <ion-select  v-model="tableSelected" ok-text="Select" cancel-text="Close" placeholder="Select Table">
                        <ion-select-option v-for="(table, index) in tableOption" :key="index" :value="table.id">{{table.text}}</ion-select-option>
                    </ion-select>
                    <span style="font-size: 9pt;">
                        <a :href="StringValue.TABLE_LAYOUT" target="_BLANK">Clik here</a> 
                    to see table layout</span>
                </div>
                
                <div style="margin-top: 20px;" v-if="dates && dates.length && timeLoaded && !isTimeLoading">
                    <ion-grid>
                        <ion-row>
                            <ion-col :size="isWeekDay ? '6': '12'" v-for="(date, index) in dates" :key="index">
                                <button 
                                    :disabled="date.activated" 
                                    :class="date.activated ?'time-selected' : 'time-unselect'" 
                                    @click="selectTime(index)">
                                    <!-- <small>{{date.subText}}</small> <br> -->
                                    <b>{{date.text}}</b> <br>
                                    <small style="padding-top: 10px;">
                                        {{date.time}}
                                    </small>
                                </button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>

                <div style="margin-top: 20px;" v-if="!dates && !dates.length && timeLoaded && !isTimeLoading">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12" >
                                <p style="color: #5a2e00;">Maaf data yang anda minta sedang tidak tersedia, silahkan atur ulang kembali dengan jadwal yang berbeda</p>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </div>
            
            <div v-if="page == 'list-guest' && !isLoading">
                <div v-for="(guest, index) in guestList" :key="index">
                    <ion-card-content  v-if="index == 0">
                        <div>
                            <ion-label style="color: #5a2e00; font-weight: bold;">Name {{index == 0 ? '* (Required)' : ' (Optional)'}}</ion-label>
                            <ion-input type="text" placeholder="Name" v-model="guest.name" />
                            <span style="color:red" v-if="guest.validateName">Name is required</span>
                        </div>
                        <div style="margin-top: 10px;">
                            <ion-label style="color: #5a2e00; font-weight: bold;">Phone Number {{index == 0 ? '* (Required)' : ' (Optional)'}}</ion-label>
                            <ion-input type="number" placeholder="Phone Number" v-model="guest.phone" />
                            <span style="color:red" v-if="guest.validatePhone">The phone number you entered does not match the format</span>
                        </div>
                        <div style="margin-top: 10px;">
                            <ion-label style="color: #5a2e00; font-weight: bold;">Email {{index == 0 ? '* (Required)' : ' (Optional)'}}</ion-label>
                            <ion-input type="email" placeholder="Email" v-model="guest.email" />
                            <span style="color:red" v-if="guest.validateEmail">The email you entered does not match the format</span>
                        </div>
                    </ion-card-content>
                </div>
            </div>
            
            <div v-if="page == 'payment' && !isLoading">
                <ion-card  style="background: #ffffff;">
                    <ion-card-content>
                        <ion-label style="color: #5a2e00; font-weight: bold;">Order</ion-label>
                        <ion-grid>
                            <ion-row>
                                <ion-col size="4" >
                                    <label style="color: #5a2e00;">Date</label>
                                </ion-col>
                                <ion-col size="8" style="color: #5a2e00;">
                                    {{getDateAndDay()}}
                                </ion-col>
                                <ion-col size="4" >
                                    <label style="color: #5a2e00;">Time</label>
                                </ion-col>
                                <ion-col size="8" style="color: #5a2e00;">
                                    {{getSelectedTime()}}
                                </ion-col>
                                <ion-col size="4" >
                                    <label style="color: #5a2e00;">Total Visitor</label>
                                </ion-col>
                                <ion-col size="8" style="color: #5a2e00;">
                                    {{payload.qty}} Visitor{{payload.qty > 1 ? "s":""}}
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-card-content>
                </ion-card>
                <ion-card  style="background: #ffffff;">
                    <ion-card-content>
                        <label v-if="!isXendit" style="color: #5a2e00; font-weight: bold;">{{paymentMessage}}</label>
                        <ion-grid>
                            <ion-row>
                                <ion-col size="6" >
                                    <b style="color: #5a2e00;">Booking Code</b>
                                </ion-col>
                                <ion-col size="6" class="text-end" style="color: #5a2e00;">
                                    {{paymentData.booking_code}}
                                </ion-col>
                                
                            </ion-row>
                        </ion-grid>
                        <small style="color: #5a2e00;">
                            <a :href="StringValue.QISCUS" target="_BLANK"><b>Klik disini</b></a> untuk konfirmasi pemesanan anda  atau hubungi Whatsapp kami dengan 
                            <a :href="StringValue.WA" target="_BLANK"><b>klik disini</b></a>
                        </small>
                    </ion-card-content>
                </ion-card>
            </div>

            <div v-if="page != 'payment' && !isLoading" style="margin-top: 30px;">
                <ion-grid>
                    <ion-row>
                        <ion-col size="6" class="text-center">
                            <button class="next-prev" @click="doBack()" style="background-color: #cbcbcb00; text-decoration: underline; font-size: 13pt;color: #5a2e00;">
                                Back
                            </button>
                        </ion-col>
                        <ion-col size="6" class="text-center">
                            <button class="next-prev" :disabled="isNextDisabled" @click="doNext()" 
                            style="background-color: #cbcbcb00; text-decoration: underline; font-size: 13pt; color: #5a2e00;">
                                Next
                            </button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>

            <div v-if="page == 'payment' && !isLoading" style="margin-top: 30px;">
                <ion-grid>
                    <ion-row>
                        <ion-col size="12" class="text-center">
                            <button class="next-prev" @click="doBack()" style="background-color: #cbcbcb00; text-decoration: underline; font-size: 13pt;color: #5a2e00;">
                                Home
                            </button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>

        </div>
    </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonPage, 
    IonLabel, 
    IonInput, 
    IonSelect, 
    IonSelectOption,
    IonGrid,
    IonRow,
    IonCol, 
    IonCard, 
    IonCardContent,
    IonSpinner,
} from '@ionic/vue';
import { AtsiriEat, PaymentApi } from "@/api/AtsiriApi"
import { mapActions } from 'vuex'
import stringVal from '@/utils/constant'

export default defineComponent({
    name: 'AtsiriEatSchedluer',
    components: {
        IonPage,
        IonLabel,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonGrid,
        IonRow,
        IonCol,
        IonCard, 
        IonCardContent,
        IonSpinner,
    },
    data: () => ({
        payload: {
            date: null,
            endDate: null,
            qty: null
        },
        dates: [],
        timeLoaded: false,

        page: 'schedule',
        start_date: null,
        guestList: [],

        bookPayload: null,
        vaList: [],
        vaSelected: 0,

        paymentData: null,
        isXendit: true,
        paymentMessage: null,
        isLoading: true,
        isTimeLoading: false,

        tableSelected: null,
        tableOption: [],
        StringValue: stringVal
    }),
    watch: {
        'payload.qty': function(value) {
            if(value && !this.isLoading) {
                this.getSchedule(value)
                this.generateGuestList()
            }
        },
        'payload.date': function(value) {
            this.start_date = value
            if(this.payload.qty && !this.isLoading) {
                this.getSchedule(value)
            }
        }
    },
    computed: {
        isDateSelected: function() {
            if(this.payload.date) {
                return true
            }
            return false
        },
        isWeekDay() {
            const weekday = ["1","2","3","4","5"];
            let day = new Date(this.start_date)
            if(weekday.some(e => Number(e) == Number(day.getDay())) ) {
                return true
            }
            return false
        },
        minDate: function() {
            let today = new Date()
            let pad = (num, size) => {
                num = num.toString();
                while (num.length < size) num = "0" + num;
                return num;
            }
            return `${today.getFullYear()}-${pad((today.getMonth()+1)+'', 2) }-${pad((today.getDate()+1)+'', 2) }`
        },
        isNextDisabled: function() {
            if(this.page == 'schedule') {
                var isTimeSelected = this.dates.some(e => e.activated == true)
                if(this.payload.qty && this.payload.date && isTimeSelected && this.tableSelected) {
                    return false
                } 
            } 
            if(this.page == 'list-guest') {
                const validateWhitespace = (text) => {
                    if(String(text.replaceAll(/\s/g,'').length) > 0) {
                        return true
                    }
                    return false
                }
                const validatePhoneNumber = (text) => {
                    var phoneNum = /^\d*$/;
                    if(phoneNum.test(text) && String(text).length > 8) {
                        return true
                    }
                    return false
                }

                const validateEmail = (text) => {
                    var emailMatch = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                    if(emailMatch.test(text)) {
                        return true
                    }
                    return false
                }
                let guest = this.guestList[0]
                
                this.guestList.map((guest, index) => {
                    this.validateInput(index, guest.name, "name")
                    this.validateInput(index, guest.phone, "phone")
                    this.validateInput(index, guest.email, "email")
                })
                if( (guest.name != null && validateWhitespace(guest.name)) && 
                    ( guest.phone != null && validateWhitespace(guest.phone) && validatePhoneNumber(guest.phone) ) &&
                    ( guest.email != null && validateWhitespace(guest.email) && validateEmail(guest.email))) {
                    return false
                }
            } 
            if(this.page == 'checkout') {
               return false
            }

            
            return true
        }
    },
    ionViewWillEnter(){
        this.isLoading = true
        let today = new Date()
        let pad = (num, size) => {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        }
        this.payload.date = `${today.getFullYear()}-${pad((today.getMonth()+1)+'', 2) }-${pad((today.getDate()+1)+'', 2) }`
        this.setDefault()
    },
    ionViewDidEnter(){
        this.isLoading = false
    },
    mounted() {
        let today = new Date()
        let pad = (num, size) => {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        }
        this.payload.date = `${today.getFullYear()}-${pad((today.getMonth()+1)+'', 2) }-${pad((today.getDate()+1)+'', 2) }`
        this.isLoading = false
    },
    methods: {
        validateInput(index, value, opt) {
            const validateWhitespace = (text) => {
                if(String(text.replaceAll(/\s/g,'').length) > 0) {
                    return true
                }
                return false
            }
            const validatePhoneNumber = (text) => {
                var phoneNum = /^\d*$/;
                if(phoneNum.test(text) && String(text).length > 8) {
                    return true
                }
                return false
            }

            const validateEmail = (text) => {
                var emailMatch = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if(emailMatch.test(text)) {
                    return true
                }
                return false
            }
            switch (opt) {
                case "name":
                    if(value && validateWhitespace(value)) {
                        this.guestList[index].validateName = false
                    }
                    if(value && !validateWhitespace(value)) {
                        this.guestList[index].validateName = true
                    }
                    break;
                case "phone":
                    if(value && validateWhitespace(value) && validatePhoneNumber(value) ) {
                        this.guestList[index].validatePhone = false
                    }
                    if(value && !validatePhoneNumber(value) ) {
                        this.guestList[index].validatePhone = true
                    }
                    break;
                default:
                    if(value && validateWhitespace(value) ) {
                        this.guestList[index].validateEmail = true
                    }
                    if(value && validateWhitespace(value) && validateEmail(value) ) {
                        this.guestList[index].validateEmail = false
                    }
                    if(value && validateWhitespace(value) && !validateEmail(value) ) {
                        this.guestList[index].validateEmail = true
                    }
                    break;
            }
        },
        setDefault() {
            let today = new Date()
            let pad = (num, size) => {
                num = num.toString();
                while (num.length < size) num = "0" + num;
                return num;
            }

            this.payload.qty = null
            this.dates = []
            this.timeLoaded= false

            this.page= 'schedule'
            this.start_date= `${today.getFullYear()}-${pad((today.getMonth()+1)+'', 2) }-${pad((today.getDate()+1)+'', 2) }`
            this.guestList= []

            this.bookPayload= null
            this.vaList= []
            this.vaSelected= 0

            this.paymentData= null
            this.isXendit= true
            this.paymentMessage= null

            this.tableSelected= null
            this.tableOption= []
        },
        ...mapActions(["fireError"]),
        getSchedule() {
            this.isTimeLoading = true
            try {
                AtsiriEat.getSchedule( this.reformatDateRequest(this.payload.date), 
                    this.reformatDateRequest(this.payload.date), this.payload.qty ).then(resp => {
                    this.dates = []
                    this.tableSelected = null
                    this.tableOption = []
                    if(resp.data && resp.data.data.length) {
                        let respSchedule = resp.data.data
                        respSchedule.map((_schedule, index) => {
                            this.tableOption.push({
                                text: 'Table '+ _schedule["Table Name"],
                                id: _schedule["Table Name"]
                            })
                        })
                        if(this.isWeekDay) {
                            this.dates.push({
                                id: 0,
                                time: "10:00 s/d 16:00",
                                activated: false,
                                disabled: false,
                                start_time: "10:00:00",
                                end_time: "16:00:00",
                                text: "Weekday Lunch",
                                subText: 'Ala carte'
                            })
                            this.dates.push({
                                id: 1,
                                time: "17:00 s/d 21:00",
                                activated: false,
                                disabled: false,
                                start_time: "17:00:00",
                                end_time: "21:00:00",
                                text: "Weekday Dinner",
                                subText: 'Set Menu Only'
                            })
                        } else {
                            this.dates.push({
                                id: 1,
                                time: "17:00 s/d 21:00",
                                activated: false,
                                disabled: false,
                                start_time: "17:00:00",
                                end_time: "21:00:00",
                                text: "Weekend",
                                subText: 'Set Menu Only'
                            })
                        }
                    }
                }).finally(() => {
                    this.timeLoaded = true
                    setTimeout(() => {
                        this.isTimeLoading = false
                    }, 500)
                }).catch(error => {
                    let message = " Mohon Maaf, terjadi kesalahan "
                    if(error.code) {
                        message += `([${error.code}] ${error.message}) `
                    }
                    if(error.response && error.response.data && error.response.data.message) {
                        var errorMessage = error.response.data.message
                        if(typeof errorMessage === "string") {
                            message += ` = ${errorMessage} `
                        } else if(typeof errorMessage === "object") {
                            if(errorMessage.message) {
                                message += ` = ${errorMessage.message}, Silahkan coba beberapa saat lagi!`
                            }
                        }
                    }
                    this.fireError(message)
                })
            } catch (error) {
                setTimeout(() => {
                    this.isLoading = false
                }, 2000)
                let message = " Mohon Maaf, terjadi kesalahan "
                if(error.code) {
                    message += `([${error.code}] ${error.message}) `
                }
                if(error.response && error.response.data && error.response.data.message) {
                    var errorMessage = error.response.data.message
                    if(typeof errorMessage === "string") {
                        message += ` = ${errorMessage} `
                    } else if(typeof errorMessage === "object") {
                        if(errorMessage.message) {
                            message += ` = ${errorMessage.message}, Silahkan coba beberapa saat lagi!`
                        }
                    }
                }
                this.fireError(message)
            }
        },
        generateGuestList() {
            this.guestList = []
            for (let index = 0; index < this.payload.qty; index++) {
                this.guestList.push({
                    name: null,
                    validateName: false,
                    phone: null,
                    validatePhone: false,
                    email: null,
                    validateEmail: false
                })
            }
             
        },
        generateBodyBooking() {
            let params = {
                table_name: this.tableSelected,
                start_date: this.start_date,
                end_date: this.start_date,
                quantity: this.payload.qty,
                guests: []
            }
            this.dates.map(_date => {
                if(_date.activated) {
                    params.start_date += " "+_date.start_time
                    params.end_date += " "+_date.end_time
                }
            })
            this.guestList.map(_guest => {
                if(_guest.name && _guest.phone && _guest.email) {
                    params.guests.push({
                        name: _guest.name,
                        phone: _guest.phone,
                        email: _guest.email
                    })
                }
            })
            return params
        },
        reformatDateRequest(date) {
            console.log('date', date)
            let tempDate = date.split('-')
            return `${tempDate[2]}/${tempDate[1]}/${tempDate[0]}`
        },
        selectTime(id) {
            this.dates.map(_date => {
                _date.activated = false
            })
            this.dates[id].activated = true
        },
        getPrice() {
            let price = null
            this.dates.map(_date => {
                if(_date.activated) {
                    price = _date.price
                }
            })
            return price
        },
        
        validateData() {
            let valid = true
            if(!this.guestList || this.guestList.length == 0) {
                return false
            }
            this.guestList.map((_guest, index) => {
                if(index == 0) {
                    if((!_guest.name || !_guest.name.length) || (!_guest.phone || !_guest.phone.length) || (!_guest.email || !_guest.email.length)) {
                        valid = false
                    }
                }
            })
            return valid
        },
        async getVaList() {
            await PaymentApi.getVaList().then(resp => {
                if(resp.data && resp.data.data && resp.data.data.length) {
                    var bankList = resp.data.data
                    bankList.map(_bank => {
                        if(_bank.is_activated) {
                            this.vaList.push({
                                name: _bank.name,
                                code: _bank.code
                            })
                        }
                    })
                }
            })
        },
        getSelectedTime() {
            let time = ''
            this.dates.map(_date => {
                if(_date.activated) {
                    time = _date.time
                }
            })
            return time
        },
        getDateAndDay() {
            const weekday = ["Minggu","Senin","Selasa","Rabu","Kamis","Jumat","Sabtu"];
            const monthList = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"]
            let day = new Date(this.start_date)
            let text = `${day.getDate()} ${monthList[day.getMonth()]} ${day.getFullYear()}`

            return weekday[day.getDay()] +" "+ text
        },
        async doNext() {
            switch (this.page) {
                case 'schedule':
                    this.page = 'list-guest'
                    this.generateGuestList()
                    break;
                
                case 'list-guest':
                    var request = this.validateData()
                    if(request) {
                        try {
                            this.isLoading = true
                            var response = await AtsiriEat.postBookingSchedule(this.generateBodyBooking())
                            if(response.data && response.data.data ) {
                                this.paymentMessage = response.data.message
                                this.isXendit = false
                                this.paymentData = response.data.data
                                this.page = 'payment'
                                setTimeout(() => {
                                    this.isLoading = false
                                }, 500)
                            } else {
                                throw { error: true}
                            }
                        } catch (error) {
                            setTimeout(() => {
                                this.isLoading = false
                            }, 2000)
                            let message = " Mohon Maaf, terjadi kesalahan "
                            if(error.code) {
                                message += `([${error.code}] ${error.message}) `
                            }
                            if(error.response && error.response.data && error.response.data.message) {
                                var errorMessage = error.response.data.message
                                if(typeof errorMessage === "string") {
                                    message += ` = ${errorMessage} `
                                } else if(typeof errorMessage === "object") {
                                    if(errorMessage.message) {
                                        message += ` = ${errorMessage.message}, Silahkan coba beberapa saat lagi!`
                                    }
                                }
                            }
                            this.fireError(message)
                            console.log('error ->', error)
                        }
                    }
                    break;
                default:
                    this.page = 'schedule'
                    break;
            }
        },
        doBack() {
            switch (this.page) {
                case 'schedule':
                    this.$router.back()
                    break;
                case 'list-guest':
                    this.page = "schedule"
                    break;
                case 'payment':
                    this.$router.push('/home')
                    break;
                default:
                    this.page = "schedule"
                    break;
            }
        }
        
    }
    
})
</script>

<style scoped>
#container-museum-schedule {
    position: absolute;
    width: 100%;
    top: 22%;
    padding-left: 30px;
    padding-right: 30px;
    max-height: 500px;
    overflow: auto;
}

ion-input, ion-select {
    padding: 10.9432px 14.5909px !important;
    background: #FAFAFA;
    border: 0.911932px solid rgba(0, 0, 0, 0.6);
    color: rgb(20, 20, 20);
    border-radius: 7.29545px;
    --placeholder-color: rgb(87, 70, 70);
    --placeholder-opacity: 1;

}

ion-select {
    height: 63px;
}

.time-unselect {
    box-sizing: border-box;
    width: 100%;
    height: 49px;
    background: #FFFFFF;
    border: 1px solid #84C7C3;
    color: #222428;
    border-radius: 4px;
}

.time-selected {
    box-sizing: border-box;
    width: 100%;
    height: 49px;
    color: #e5e5e5;
    background: #e36e40;
    border: 1px solid #84C7C3;
    border-radius: 4px;
}

ion-card {
    background: #f0ffff61;
}

.ats-select {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border-color: #84C7C3;
}

select.ats-select:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

ion-col.text-end {
    text-align: end;
}

ion-col.text-center {
    text-align: center;
}
button.next-prev[disabled] {
    color: #c1c0c0 !important;
}


</style>
