
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/vue';

export default defineComponent({
  name: 'AtsiriEatPage',
  components: {
    IonGrid, 
    IonRow, 
    IonCol,
    IonButton
  }
});
