import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import WrapPage from '@/views/WrapPage.vue';

import HomePage from '../views/HomePage.vue';
import AtsiriEatPage from '@/views/AtsiriEatPage.vue';
import AtsiriEatMenu from '@/views/AtsiriEatMenus.vue';
import AtsiriEatScheduler from "@/views/AtsiriEatScheduler.vue";
import AtsiriMuseum from "@/views/AtsiriMuseum.vue";

import AtsiriMuseumSchedule from "@/views/AtsiriMuseumScheduler.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: WrapPage,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: HomePage,
        meta: { transition: 'none' },
      },
      {
        path: '/atsiri-museum',
        name: 'atsiri-museum',
        component: AtsiriMuseum
      },
      {
        path: '/atsiri-museum/schedule',
        name: 'atsiri-museum-schedule',
        component: AtsiriMuseumSchedule
      },
      {
        path: '/atsiri-eat',
        name: 'atsiri-eat',
        component: AtsiriEatPage,
        children: [
          {
            path: '',
            name: 'atsiri-eat-menu',
            component: AtsiriEatMenu
          },
          {
            path: 'schedule',
            name: 'atsiri-eat-schedule',
            component: AtsiriEatScheduler
          },
        ]
      }
    ]
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
