
import { defineComponent } from 'vue';
import { IonPage } from '@ionic/vue';
import stringVal from '@/utils/constant'

export default defineComponent({
  name: 'HomePage',
  data: () => ({
    hyperlink: stringVal
  }),
  components: {
    IonPage
  },
});
