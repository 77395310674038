const axios = require('axios').default;

const base_url  = process.env.VUE_APP_BASE_URL;
const prefix    = process.env.VUE_APP_PREFIX_URL;
const url       = base_url+prefix;

export const AtsiriEat = {
    getSchedule: (startDate, endDate, qty) => {
        return axios.get(`${url}/sarinah/booking/eat/schedule?start_date=${startDate}&end_date=${endDate}&quantity=${qty}`)
    },
    postBookingSchedule: (params) => {
        return axios.post(`${url}/sarinah/booking/eat/submit`, params)
    }
}

export const PaymentApi = {
    getVaList: () => {
        return axios.get(`${url}/xdt/va/bank`)
    },
    postCreateVa: (action = 'museum', param) => {
        let endpoint = action == 'museum' ? 'sarinah/booking/museum/payment-request' : 'sarinah/booking/eat/payment-request'
        return axios.post(`${url}/${endpoint}`, param)
    }
}

export const AtsiriMuseum = {
    getSchedule: (date, qty) => {
        return axios.get(`${url}/sarinah/booking/museum/schedule?date=${date}&quantity=${qty}`)
    },
    postBookingSchedule: (params) => {
        return axios.post(`${url}/sarinah/booking/museum/submit`, params)
    }
}