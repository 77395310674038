<template>
    <div>
        <div id="museum-header-logo-white" v-if="isAtsiriMuseumPage">
            <div class="warp-text-right" align="center">
                
                <img src="@/theme/images/Logo-Mini-Museum.png" alt="Background-component" style="width: 65%">
            </div>
        </div>
        <div id="museum-header-logo-green" v-if="isAtsiriMuseumSchedule">
            <div class="warp-text-right" align="center">
                <img v-if="isAtsiriMuseumSchedule" src="@/theme/images/logo-green.png" alt="Background-component" style="width: 65%">
                
            </div>
        </div>
        <div class="text-museum-subheader" v-if="isAtsiriMuseumPage" align="center">
            <p style="padding-top: 30px"> 
                <b style="font-family: 'AppFontBold';">
                    a micro version of our museum <br>
                    at Rumah Atsiri Indonesia 
                </b>
            </p>
            <p style="font-family: 'AppFont';"> miniatur kecil dan playground dari <br> Rumah Atsiri Indonesia. </p>
            <img src="@/theme/images/do-dont.png" class="dos-donts">
        </div>
        
    </div>
</template>

<script>
// import AtsiriFontWhite from "./AtsiriFontWhite.vue"
// import AtsiriFontGreen from "./AtsiriFontGreen.vue"

export default {
    components: {
        // AtsiriFontWhite,
        // AtsiriFontGreen
    },
    computed: {
        isAtsiriMuseumPage() {
            if(this.$route.name == 'atsiri-museum' ) {
                return true
            }
            return false
        },
        isAtsiriMuseumSchedule() {
            if(this.$route.name == 'atsiri-museum-schedule' ) {
                return true
            }
            return false
        }
    }
}
</script>

<style scoped>
.dos-donts {
    width: 40%;
    position: relative;
    top: 9px;
}
#museum-header-logo-white {
    position: relative;
    top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
}

#museum-header-logo-green {
    position: relative;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
}

#museum-header-logo-white .atsiri-svg-white {
    padding: 3px;
}

#museum-header-logo-white .warp-text-right {
    display: block;
}
.font-logo-atsiri-museum {
    width: 35px;
    height: 32px;
}
.mr--5 {
    padding-right: 5px;
}

.mr--2 {
    padding-right: 2px;
}

.text-museum-subheader {
    position: relative;
    width: 100%;
    height: 86px;
    /* left: calc(50% - 319px/2 - 0.5px); */
    top: 90px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center; */
    letter-spacing: 0.05em;
    z-index: 1;
    color: #FFFFFF;
}
</style>
