import Vuex from 'vuex';
export default new Vuex.Store({
    state: {
        showError: false,
        messageError: null
    },

    mutations: {
        setError(state, data) {
            state.showError = true
            state.messageError = data
            setTimeout(() => {
                state.showError = false
                state.messageError = data
            }, 5000)
        }
    },
    actions: {
        fireError({commit}, data) {
            commit('setError', data);
        }
    }
});