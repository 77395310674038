<template>
    <div>
        <logoAtsiriEat v-if="isAtsiriEatPage" />

        <div >
            <img v-if="isAtsiriMuseumPage" src="@/theme/images/bg-header-museum.png" alt="Background-component" style="width: 100%; position: absolute;">
            <!-- <img v-if="isAtsiriMuseumPage" src="@/theme/images/Box-Layer-Header-Museum.png" alt="Background-component" style="width: 100%; position: absolute;"> -->
            <!-- <img v-if="isAtsiriMuseumPage" src="@/theme/images/Logo-Mini-Museum.png" alt="Background-component"> -->
            <atsiriFontSvg v-if="isAtsiriMuseumPage || isAtsiriMuseumSchedule"  />
        </div>

        <div class="ats-row" style="margin-top: 20px;" v-if="isShow">
            <div class="ats-col-6" style="padding-right: 0px; padding-left: 10px;">
                <img src="@/theme/images/Logo-Atsiri.png" alt="logo atsiri">
            </div>
            <div class="ats-col-3 text-logo " style="color: rgba(191, 101, 72, 1); " >
                <b>A Part of</b> 
            </div>
            <div  class="ats-col-3 " style="padding-top: 15px; padding-left: 0;">
                <img src="@/theme/images/part-atsiri-indonesia.png" alt="logo" style="width: 100px;">
            </div>
        </div>
        
        <img v-if="isShow" src="@/theme/images/Bunga1.png" alt="Background-component" class="bg-component-1">
        <img v-if="isShow" src="@/theme/images/Bunga2.png" alt="Background-component" class="bg-component-2">
        <img v-if="isShow" src="@/theme/images/Bunga3.png" alt="Background-component" class="bg-component-3">
        <img v-if="isShow" src="@/theme/images/Bunga4.png" alt="Background-component" class="bg-component-4">
        <img v-if="isShow" src="@/theme/images/Bunga5.png" alt="Background-component" class="bg-component-5">
        <img v-if="isShow" src="@/theme/images/Bunga6.png" alt="Background-component" class="bg-component-6">

        <img src="@/theme/images/bungakecil1.png" alt="Background-component" class="bg-component-7">
        <img src="@/theme/images/bungakecil2.png" alt="Background-component" class="bg-component-8">
        <img src="@/theme/images/bungakecil-kuning.png" alt="Background-component" class="bg-component-9">
        <!-- <img src="@/theme/images/bungakecil-orange1.png" alt="Background-component" class="bg-component-10"> -->

        <img src="@/theme/images/bungakecil-kuning.png" alt="Background-component" class="bg-component-11">
        <img src="@/theme/images/bungakecil-kuning.png" alt="Background-component" class="bg-component-12">
        <img src="@/theme/images/bungakecil-kuning.png" alt="Background-component" class="bg-component-13">

        <img src="@/theme/images/bungakecil-orange1.png" alt="Background-component" class="bg-component-14">
        <img src="@/theme/images/bungakecil-pink2.png" alt="Background-component" class="bg-component-15">
        <img src="@/theme/images/bungakecil-orange2.png" alt="Background-component" class="bg-component-16">


    </div>
</template>

<script>
import LogoAtsiriEat from "./logoAtsiriEat.vue"
import AtsiriFontSvg from "./WraperHeaderMuseum.vue"

export default {
    components: {
        LogoAtsiriEat,
        AtsiriFontSvg,
    },
    data: () => ({
        isShow: true
    }),
    watch: {
        $route (to){
            this.initValueShowImage(to.name)
        }
    },
    computed: {
        isAtsiriEatPage() {
            if(this.$route.name == 'atsiri-eat' || this.$route.name == 'atsiri-eat-menu' || 
                this.$route.name == 'atsiri-eat-schedule') {
                return true
            }
            return false
        },
        isAtsiriMuseumPage() {
            if(this.$route.name == 'atsiri-museum' ) {
                return true
            }
            return false
        },
        isAtsiriMuseumSchedule() {
            if(this.$route.name == 'atsiri-museum-schedule' ) {
                return true
            }
            return false
        }
    },
    mounted() {
        this.initValueShowImage(this.$route.name)
    },
    methods: {
        initValueShowImage(data) {
            switch (data) {
                case "atsiri-museum":
                case "atsiri-museum-schedule":
                case "atsiri-eat-schedule":
                case "atsiri-eat-menu":
                case "atsiri-eat": {
                    this.isShow = false
                    break;
                }
                default: {
                    this.isShow = true
                    break;
                }
            }
        }
    }
}
</script>

<style scoped>
.bg-component-1 {
    position: absolute;
    width: 12%;
    height: auto;
    right: 0;
    top: 15%;
}

.bg-component-2 {
    position: absolute;
    width: 20%;
    height: auto;
    left: 0;
    top: 45%;
}

.bg-component-3 {
    position: absolute;
    width: 30%;
    height: auto;
    right: 2%;
    bottom: 0;
}

.bg-component-4 {
    position: absolute;
    width: 25%;
    height: auto;
    right: 0;
    top: 45%;
}

.bg-component-5 {
    position: absolute;
    width: 20%;
    height: auto;
    left: 0;
    top: 10%;
}

.bg-component-6 {
    position: absolute;
    width: 20%;
    height: auto;
    left: 2%;
    bottom: 0;
}
.bg-component-7 {
    position: absolute;
    left: 5%;
    bottom: 22%;
}
.bg-component-8 {
    position: absolute;
    right: 11%;
    top: 43%;
}

.bg-component-9 {
    position: absolute;
    width: 7%;
    height: auto;
    left: calc(50% - 7%/2 + 39.5px);
    top: calc(50% - 33px/2 - 384.5px);
}

.bg-component-10 {
    position: absolute;
    width: 7%;
    height: auto;
    left: calc(50% - 33px/2 + 128.5px);
    top: calc(50% - 33px/2 - 340.5px);
}

.bg-component-11 {
    position: absolute;
    width: 33px;
    height: 33px;
    left: calc(50% - 33px/2 + 160.5px);
    top: calc(50% - 33px/2 - 130.5px);
}

.bg-component-12 {
    position: absolute;
    width: 33px;
    height: 33px;
    left: calc(50% - 33px/2 + 159.5px);
    top: calc(50% - 33px/2 + 161.5px);
}

.bg-component-13 {
    position: absolute;
    width: 33px;
    height: 33px;
    left: calc(50% - 33px/2 - 159.5px);
    top: calc(50% - 33px/2 + 128.5px);
}

.bg-component-14 {
    position: absolute;
    width: 33px;
    height: 33px;
    left: calc(50% - 33px/2 - 51.5px);
    top: calc(50% - 33px/2 + 23.5px);
}

.bg-component-15 {
    position: absolute;
    width: 33px;
    height: 33px;
    left: calc(50% - 33px/2 - 159.5px);
    top: calc(50% - 33px/2 - 71.5px);
}

.bg-component-16 {
    position: absolute;
    width: 33px;
    height: 33px;
    left: calc(50% - 33px/2 + 159.5px);
    top: calc(50% - 33px/2 + 253.5px);
}

@media only screen and (min-width: 350px) and (max-width: 380px){
    .bg-component-9 {
        top: calc(50% - -25%/2 - 384.5px);
    }
}

.ats-row {
    display: flex;
    flex-wrap: nowrap;
}
.ats-col-6 {
    width: 50%;
    padding: 16px 30px;
}

.ats-col-3 {
    width: 25%;
    padding: 10px 10px;
}

.text-logo {
    text-align: end;
    font-size: 8pt;
    padding-top: 24px;
}


</style>