
import { IonApp, IonContent, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import bgImage from "@/components/bgImagesComponents.vue"
import { mapState } from 'vuex'

export default defineComponent({
  name: 'HomePage',
  components: {
    IonApp,
    IonContent,
    bgImage,
    IonRouterOutlet
  },
  computed: {
    ...mapState(["showError", "messageError"])
  },
});
