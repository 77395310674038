<template>
    <div>
        <img src="@/theme/images/logo-atsiri-eat.png" alt="atsiri-eat" class="logo-atsiri-eat" />
    </div>
</template>

<style scoped>
    .logo-atsiri-eat {
        position: absolute;
        left: calc(50% - 140px/2 + 0.5px);
        top: calc(50% - 113px/2 - 276.5px);
        width: 30%;
    }


</style>