import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-090d8fb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "warp-alert"
}
const _hoisted_2 = { class: "alert-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bg_image = _resolveComponent("bg-image")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, { class: "atsiri-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_bg_image),
          (_ctx.showError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.messageError), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_router_outlet, { animated: "false" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}