<template>
    <ion-page>
        <div id="container-museum-schedule" >
            <div v-if="isLoading" align="center" style="margin-top: 10%;">
                <ion-spinner name="circles" type="primary" style="color: #5a2e00;"></ion-spinner><br>
                <small style="color: #5a2e00;">Please wait, your request is being processed</small>
            </div>
            <div v-if="page == 'schedule' && !isLoading">
                <div>
                    <ion-label position="stacked" style="color: #5a2e00; font-weight: bold;margin-bottom: 4px">Date</ion-label>
                    <ion-input type="date" v-model="payload.date" :min="minDate" />
                </div>
                <div style="margin-top: 10px;">
                    <ion-label style="color: #5a2e00; font-weight: bold; margin-bottom: 4px">Number of visitors</ion-label>
                    <ion-select :disabled="!isDateSelected" v-model="payload.qty" ok-text="Select" cancel-text="Close" placeholder="Select number">
                        <ion-select-option value="1">1 Visitor</ion-select-option>
                        <ion-select-option value="2">2 Visitors</ion-select-option>
                        <ion-select-option value="3">3 Visitors</ion-select-option>
                        <ion-select-option value="4">4 Visitors</ion-select-option>
                        <ion-select-option value="5">5 Visitors</ion-select-option>
                    </ion-select>
                </div>

                <div v-if="isTimeLoading" align="center" style="margin-top: 20px;">
                    <ion-spinner name="circles" type="primary" style="color: #5a2e00;"></ion-spinner>
                </div>
                
                <div style="margin-top: 20px; max-height: 215px; overflow: auto;" v-if="dates && dates.length && timeLoaded && !isTimeLoading && !noTimeCanBook">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="3" v-for="(date, index) in dates" :key="index">
                                <button 
                                    :disabled="date.activated" 
                                    :class="date.activated ?'time-selected' : 'time-unselect'" 
                                    @click="selectTime(index)">
                                    {{reformatTimeToText(date.time, true)}} <br><small>s/d</small><br> {{reformatTimeToText(date.time, false)}}
                                </button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>

                <div style="margin-top: 20px;" v-if="noTimeCanBook && timeLoaded && !isTimeLoading">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="12" >
                                <small style="color: #5a2e00;">Maaf data yang anda minta sedang tidak tersedia, silahkan atur ulang kembali dengan jadwal yang berbeda</small>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </div>
            
            <div v-if="page == 'list-guest' && !isLoading">
                <div>
                    <ion-card-content v-for="(guest, index) in guestList" :key="index">
                        <div v-if="index == 0">
                            <ion-label style="color: #5a2e00; font-weight: bold; font-weight: bold; font-size: 12pt;">Visitor List</ion-label>
                            <hr>
                        </div>
                        <div>
                            <ion-label style="color: #5a2e00; font-weight: bold;">Name* (Required)</ion-label>
                            <ion-input type="text" placeholder="Name" v-model="guest.name" />
                            <span style="color:red" v-if="guest.validateName">Name is required</span>
                        </div>
                        <div style="margin-top: 10px;">
                            <ion-label style="color: #5a2e00; font-weight: bold;">Phone Number* (Required)</ion-label>
                            <ion-input type="number" placeholder="Phone Number" v-model="guest.phone" />
                            <span style="color:red" v-if="guest.validatePhone">The phone number you entered does not match the format</span>
                        </div>
                        <div style="margin-top: 10px;">
                            <ion-label style="color: #5a2e00; font-weight: bold;">Email* (Required)</ion-label>
                            <ion-input type="email" placeholder="Email" v-model="guest.email" />
                            <span style="color:red" v-if="guest.validateEmail">The email you entered does not match the format</span>
                        </div>
                    </ion-card-content>
                    <!-- <ion-card v-for="(guest, index) in guestList" :key="index">
                        
                    </ion-card> -->
                </div>
            </div>

            <div v-if="page == 'checkout' && !isLoading">
                <ion-card style="background: #ffffff;">
                    <ion-card-content>
                        <ion-label style="color: #5a2e00; font-weight: bold;">Order</ion-label>
                        <ion-grid>
                            <ion-row>
                                <ion-col size="4" >
                                    <label style="color: #5a2e00;">Date</label>
                                </ion-col>
                                <ion-col size="8" style="color: #5a2e00;">
                                    {{getDateAndDay()}}
                                </ion-col>
                                <ion-col size="4" >
                                    <label style="color: #5a2e00;">Time</label>
                                </ion-col>
                                <ion-col size="8" style="color: #5a2e00;">
                                    {{getSelectedTime()}}
                                </ion-col>
                                <ion-col size="4" >
                                    <label style="color: #5a2e00;">Total Visitor</label>
                                </ion-col>
                                <ion-col size="8" style="color: #5a2e00;">
                                    {{payload.qty}} Visitor{{payload.qty > 1 ? "s":""}}
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-card-content>
                </ion-card>
                <ion-card style="background: #ffffff;">
                    <ion-card-content>
                        <div>
                            <ion-label style="color: #5a2e00; font-weight: bold;">Payment</ion-label>
                            <ion-grid>
                                <ion-row>

                                    <ion-col size="6" >
                                        <label>Price (per ticket)</label>
                                    </ion-col>
                                    <ion-col size="3" class="text-end">Rp.</ion-col>
                                    <ion-col size="3" class="text-end">
                                         {{ formatRupiah( ""+getPrice() , " ")}}
                                    </ion-col>

                                    <ion-col size="6" >
                                        <b >Total Ticket Price</b>
                                    </ion-col>
                                    <ion-col size="3" class="text-end">Rp.</ion-col>
                                    <ion-col size="3" class="text-end">
                                        {{formatRupiah(  ""+(Number(getPrice()) * Number(payload.qty)) , "")}}
                                    </ion-col>

                                    <ion-col size="6" >
                                        <b>Administration</b>
                                    </ion-col>
                                    <ion-col size="3" class="text-end">Rp.</ion-col>
                                    <ion-col size="3" class="text-end">
                                        {{formatRupiah( ""+bookPayload.xendit_fee, "" )}}
                                    </ion-col>

                                    <ion-col size="6" >
                                        <b>Total</b>
                                    </ion-col>
                                    <ion-col size="3" class="text-end">Rp.</ion-col>
                                    <ion-col size="3" class="text-end">
                                       {{formatRupiah( ((Number(getPrice()) * Number(payload.qty)) + Number(bookPayload.xendit_fee))+"", "" ) }}
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </div>
                        <div style="margin-top: 10px;">
                            <ion-label style="color: #5a2e00;">Payment Methods</ion-label>
                            <select v-model="vaSelected" class="ats-select" style="margin-top: 5px;">
                                <option value="0" selected disabled>Select Payment Method</option>
                                <option v-for="(va, index) in vaList" :value="va.code" :key="index">{{va.name}}</option>
                            </select>
                        </div>
                    </ion-card-content>
                </ion-card>
                
            </div>
            
            <div v-if="page == 'payment' && !isLoading">
                <ion-card>
                    <ion-card-content>
                        <label v-if="!isXendit">{{paymentMessage}}</label>
                        <ion-grid>
                            <ion-row>
                                <ion-col size="6" >
                                    <b style="color: #5a2e00;">Booking Code</b>
                                </ion-col>
                                <ion-col size="6" class="text-end" style="color: #5a2e00;">
                                    {{paymentData.booking_code}}
                                </ion-col>
                                <ion-col size="6" >
                                    <b style="color: #5a2e00;">Total Cost</b>
                                </ion-col>
                                <ion-col size="3" class="text-end">Rp.</ion-col>
                                <ion-col size="3" class="text-end" style="color: #5a2e00;">
                                    {{formatRupiah( ""+((Number(getPrice()) * Number(payload.qty)) + Number(bookPayload.xendit_fee)), "" )}}
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-card-content>
                </ion-card>
                <ion-card v-if="isXendit">
                    <ion-card-content>
                        <label style="color: #5a2e00;">Virtual account: </label>
                        <ion-grid>
                            <ion-row>
                                <ion-col size="6" >
                                    <b style="color: #5a2e00;">{{paymentData.va_bank}}</b>
                                </ion-col>
                                <ion-col size="6" class="text-end" style="color: #5a2e00;">
                                    {{paymentData.va_number}}
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                        <small style="color: #5a2e00;">
                            Silahkan lakukan pembayaran dalam waktu 5 menit, 
                            kemudian <a :href="StringValue.QISCUS" target="_BLANK"><b>Klik disini</b></a> untuk konfirmasi pembayaran anda  atau hubungi Whatsapp kami dengan 
                            <a :href="StringValue.WA" target="_BLANK"><b>klik disini</b></a>
                        </small>
                    </ion-card-content>
                </ion-card>
            </div>

            <div v-if="page != 'payment' && !isLoading" style="margin-top: 30px;">
                <ion-grid>
                    <ion-row>
                        <ion-col size="6" class="text-center">
                            <button class="next-prev" @click="doBack()" style="background-color: #cbcbcb00; text-decoration: underline; font-size: 13pt;color: #5a2e00;">
                                Back
                            </button>
                        </ion-col>
                        <ion-col size="6" class="text-center">
                            <button class="next-prev" :disabled="isNextDisabled" @click="doNext()" 
                            style="background-color: #cbcbcb00; text-decoration: underline; font-size: 13pt; color: #5a2e00;">
                                Next
                            </button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>

            <div v-if="page == 'payment' && !isLoading" style="margin-top: 30px;">
                <ion-grid>
                    <ion-row>
                        <ion-col size="12" class="text-center">
                            <button class="next-prev" @click="doBack()" style="background-color: #cbcbcb00; text-decoration: underline; font-size: 13pt;color: #5a2e00;">
                                Home
                            </button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>

        </div>
    </ion-page>
</template>

<script>
const excludeBankList = ["BSI", "SAHABAT_SAMPOERNA", "BJB"]
import { defineComponent } from 'vue';
import { IonPage, 
    IonLabel, 
    IonInput, 
    IonSelect, 
    IonSelectOption,
    IonGrid,
    IonRow,
    IonCol, 
    IonCard, 
    IonCardContent,
    IonSpinner,
} from '@ionic/vue';
import { AtsiriMuseum, PaymentApi } from "@/api/AtsiriApi"
import { mapActions } from 'vuex'
import stringVal from '@/utils/constant'

export default defineComponent({
    name: 'AtsiriMuseumSchedluer',
    components: {
        IonPage,
        IonLabel,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonGrid,
        IonRow,
        IonCol,
        IonCard, 
        IonCardContent,
        IonSpinner,
    },
    data: () => ({
        payload: {
            date: null,
            qty: null
        },
        dates: [],
        timeLoaded: false,

        page: 'schedule',
        start_date: null,
        guestList: [],

        bookPayload: null,
        vaList: [],
        vaSelected: 0,

        paymentData: null,
        isXendit: true,
        paymentMessage: null,

        isLoading: true,
        isTimeLoading: false,
        noTimeCanBook: false,

        StringValue: stringVal
    }),
    watch: {
        'payload.qty': function(value) {
            if(value && !this.isLoading) {
                this.getSchedule(value)
                this.generateGuestList()
            }
        },
        'payload.date': function(value) {
            this.start_date = value
            if(this.payload.qty && !this.isLoading) {
                this.getSchedule(value)
            }
        }
    },
    computed: {
        isDateSelected: function() {
            if(this.payload.date) {
                return true
            }
            return false
        },
        isNextDisabled: function() {
            if(this.page == 'schedule') {
                var isTimeSelected = this.dates.some(e => e.activated == true)
                if(this.payload.qty && this.payload.date && isTimeSelected) {
                    return false
                } 
            } 
            if(this.page == 'list-guest') {
                const validateWhitespace = (text) => {
                    if(String(text.replaceAll(/\s/g,'').length) > 0) {
                        return true
                    }
                    return false
                }
                const validatePhoneNumber = (text) => {
                    var phoneNum = /^\d*$/;
                    if(phoneNum.test(text) && String(text).length > 8) {
                        return true
                    }
                    return false
                }

                const validateEmail = (text) => {
                    var emailMatch = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                    if(emailMatch.test(text)) {
                        return true
                    }
                    return false
                }
                this.guestList.map((guest, index) => {
                    this.validateInput(index, guest.name, "name")
                    this.validateInput(index, guest.phone, "phone")
                    this.validateInput(index, guest.email, "email")
                })

                if(this.guestList.every(e => 
                    ( e.name != null && validateWhitespace(e.name) ) && 
                    ( e.phone != null && validateWhitespace(e.phone) && validatePhoneNumber(e.phone)) && 
                    ( e.email != null && validateWhitespace(e.email) && validateEmail(e.email))
                    )) {
                    return false
                }
                
            } 
            if(this.page == 'checkout') {
               return false
            }

            
            return true
        },
        minDate: function() {
            let today = new Date()
            let pad = (num, size) => {
                num = num.toString();
                while (num.length < size) num = "0" + num;
                return num;
            }
            return `${today.getFullYear()}-${pad((today.getMonth()+1)+'', 2) }-${pad((today.getDate()+1)+'', 2) }`
        },
        minHour: function() {
            let today = new Date()
            return today.getHours()
        },
        isToday() {
            let today = new Date()
            let pad = (num, size) => {
                num = num.toString();
                while (num.length < size) num = "0" + num;
                return num;
            }
            let checkToday = `${today.getFullYear()}-${pad((today.getMonth()+1)+'', 2) }-${pad((today.getDate())+'', 2) }`
            if(this.payload.date == checkToday) {
                return true
            }
            return false
        }
    },
    mounted() {
        let today = new Date()
        let pad = (num, size) => {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        }
        this.payload.date = `${today.getFullYear()}-${pad((today.getMonth()+1)+'', 2) }-${pad((today.getDate()+1)+'', 2) }`
        this.isLoading = false
    },
    ionViewWillEnter(){
        this.isLoading = true
        let today = new Date()
        let pad = (num, size) => {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        }
        this.payload.date = `${today.getFullYear()}-${pad((today.getMonth()+1)+'', 2) }-${pad((today.getDate()+1)+'', 2) }`
        this.setDefault()
    },
    ionViewDidEnter(){
        this.isLoading = false
    },
    methods: {
        validateInput(index, value, opt) {
            const validateWhitespace = (text) => {
                if(String(text.replaceAll(/\s/g,'').length) > 0) {
                    return true
                }
                return false
            }
            const validatePhoneNumber = (text) => {
                var phoneNum = /^\d*$/;
                if(phoneNum.test(text) && String(text).length > 8) {
                    return true
                }
                return false
            }

            const validateEmail = (text) => {
                var emailMatch = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if(emailMatch.test(text)) {
                    return true
                }
                return false
            }
            switch (opt) {
                case "name":
                    if(value && validateWhitespace(value)) {
                        this.guestList[index].validateName = false
                    }
                    if(value && !validateWhitespace(value)) {
                        this.guestList[index].validateName = true
                    }
                    break;
                case "phone":
                    if(value && validateWhitespace(value) && validatePhoneNumber(value) ) {
                        this.guestList[index].validatePhone = false
                    }
                    if(value && !validatePhoneNumber(value) ) {
                        this.guestList[index].validatePhone = true
                    }
                    break;
                default:
                    if(value && validateWhitespace(value) ) {
                        this.guestList[index].validateEmail = true
                    }
                    if(value && validateWhitespace(value) && validateEmail(value) ) {
                        this.guestList[index].validateEmail = false
                    }
                    if(value && validateWhitespace(value) && !validateEmail(value) ) {
                        this.guestList[index].validateEmail = true
                    }
                    break;
            }
        },
        setDefault() {
            this.payload.qty = null
            this.dates = []
            this.timeLoaded = false

            this.page = 'schedule'
            this.start_date = null
            this.guestList = []

            this.bookPayload = null
            this.vaList = []
            this.vaSelected = 0

            this.paymentData = null
            this.isXendit = true
            this.paymentMessage= null
            this.noTimeCanBook = false
        },
        ...mapActions(["fireError"]),
        checkTimeSchedule(time) {
            let hour = time.split(":")[0]
            if(hour && (Number(hour) > this.minHour)) {
                return true
            }
            return false
        },
        getSchedule() {
            this.isTimeLoading = true
            try {
                AtsiriMuseum.getSchedule( this.reformatDateRequest(this.payload.date), this.payload.qty ).then(resp => {
                    this.dates = []
                    if(resp.data && resp.data.data.length) {
                        let respSchedule = resp.data.data
                        respSchedule.map((_schedule, index) => {
                            if(this.isToday ) {
                                if(this.checkTimeSchedule(_schedule["Time Desc"])) {
                                    this.dates.push({
                                        id: index,
                                        raw_time: _schedule["Time Desc"],
                                        time: _schedule["Time Desc"].replace("-", " s/d "),
                                        price: _schedule.price_perunit,
                                        activated: false,
                                        disabled: false
                                    })
                                }
                            } else {
                                this.dates.push({
                                    id: index,
                                    raw_time: _schedule["Time Desc"],
                                    time: _schedule["Time Desc"].replace("-", " s/d "),
                                    price: _schedule.price_perunit,
                                    activated: false,
                                    disabled: false
                                })
                            }
                        })
                    }
                }).finally(() => {
                    if(this.dates.length === 0) {
                        this.noTimeCanBook = true
                    } else {
                        this.noTimeCanBook = false
                    }
                    this.timeLoaded = true
                    setTimeout(() => {
                        this.isTimeLoading = false
                    }, 500)
                })
            } catch (error) {
                setTimeout(() => {
                    this.isLoading = false
                }, 2000)
                let message = " Mohon Maaf, terjadi kesalahan "
                if(error.code) {
                    message += `([${error.code}] ${error.message}) `
                }
                if(error.response && error.response.data && error.response.data.message) {
                    var errorMessage = error.response.data.message
                    if(typeof errorMessage === "string") {
                        message += ` = ${errorMessage} `
                    } else if(typeof errorMessage === "object") {
                        if(errorMessage.message) {
                            message += ` = ${errorMessage.message}, Silahkan coba beberapa saat lagi!`
                        }
                    }
                }
                this.fireError(message)
            }
        },
        generateGuestList() {
            this.guestList = []
            for (let index = 0; index < this.payload.qty; index++) {
                this.guestList.push({
                    name: null,
                    validateName: false,
                    phone: null,
                    validatePhone: false,
                    email: null,
                    validateEmail: false
                })
            }
             
        },
        generateBodyBooking() {
            let params = {
                start_date: this.start_date,
                quantity: this.payload.qty,
                guests: []
            }
            this.dates.map(_date => {
                if(_date.activated) {
                    params.start_date += " "+_date.raw_time.split('-')[0]
                }
            })
            this.guestList.map(_guest => {
                params.guests.push({
                    name: _guest.name,
                    phone: _guest.phone,
                    email: _guest.email
                })
            })
            return params
        },
        reformatDateRequest(date) {
            let tempDate = date.split('-')
            return `${tempDate[2]}/${tempDate[1]}/${tempDate[0]}`
        },
        selectTime(id) {
            this.dates.map(_date => {
                _date.activated = false
            })
            this.dates[id].activated = true
        },
        getPrice() {
            let price = null
            this.dates.map(_date => {
                if(_date.activated) {
                    price = _date.price
                }
            })
            return price
        },
        validateData() {
            let valid = true
            if(!this.guestList || this.guestList.length == 0) {
                return false
            }
            this.guestList.map(_guest => {
                if((!_guest.name || !_guest.name.length) || (!_guest.phone || !_guest.phone.length) || (!_guest.email || !_guest.email.length)) {
                    valid = false
                }
            })
            return valid
        },
        async getVaList() {
            await PaymentApi.getVaList().then(resp => {
                if(resp.data && resp.data.data && resp.data.data.length) {
                    var bankList = resp.data.data
                    this.vaList = []
                    bankList.map(_bank => {
                        if(_bank.is_activated && !excludeBankList.some(e => e == _bank.code)) {
                            this.vaList.push({
                                name: _bank.name,
                                code: _bank.code
                            })
                        }
                    })
                }
            })
        },
        getSelectedTime() {
            let time = ''
            this.dates.map(_date => {
                if(_date.activated) {
                    time = _date.time
                }
            })
            return time
        },
        getDateAndDay() {
            const weekday = ["Minggu","Senin","Selasa","Rabu","Kamis","Jumat","Sabtu"];
            const monthList = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"]
            let day = new Date(this.start_date)
            let text = `${day.getDate()} ${monthList[day.getMonth()]} ${day.getFullYear()}`

            return weekday[day.getDay()] +" "+ text
        },
        reformatTimeToText(time, isStart = true) {
            let arrTime = time.split("s/d")
            let textTime = time
            if(arrTime[0] && arrTime[1]) {
                let arrStart = arrTime[0].split(":")
                let arrEnd = arrTime[1].split(":")
                if(arrStart[0] && arrStart[1] && arrEnd[0] && arrEnd[1]) {
                    if(isStart) {
                        textTime = `${arrStart[0]}:${arrStart[1]}`
                    } else {
                        textTime = `${arrEnd[0]}:${arrEnd[1]}`
                    }
                }
            }
            return textTime
        },
        async doNext() {
            switch (this.page) {
                case 'schedule':
                    this.generateGuestList()
                    this.page = 'list-guest'
                    break;
                
                case 'list-guest':
                    var request = this.validateData()
                    if(request) {
                        try {
                            this.isLoading = true
                            var response = await AtsiriMuseum.postBookingSchedule(this.generateBodyBooking())
                            if(response.data && response.data.data ) {
                                var resp =  response.data.data
                                if(resp.booked === false) {
                                    await this.getVaList()
                                    this.bookPayload = response.data.data
                                    this.page = "checkout"
                                    this.isXendit = true
                                } else {
                                    this.isXendit = false
                                    this.paymentMessage = response.data.message
                                    this.paymentData = response.data.data
                                    this.page = 'payment'
                                }
                                setTimeout(() => {
                                    this.isLoading = false
                                }, 500)
                            } else {
                                throw { error: true}
                            }
                        } catch (error) {
                            setTimeout(() => {
                                this.isLoading = false
                            }, 2000)
                            let message = " Mohon Maaf, terjadi kesalahan "
                            if(error.code) {
                                message += `([${error.code}] ${error.message}) `
                            }
                            if(error.response && error.response.data && error.response.data.message) {
                                var errorMessage = error.response.data.message
                                if(typeof errorMessage === "string") {
                                    message += ` = ${errorMessage} `
                                } else if(typeof errorMessage === "object") {
                                    if(errorMessage.message) {
                                        message += ` = ${errorMessage.message}, Silahkan coba beberapa saat lagi!`
                                    }
                                }
                            }
                            this.fireError(message)
                            console.log('error ->', error)
                        }
                    }
                    break;
                case 'checkout':
                    var param = {
                        booking_code: this.bookPayload.booking_code,
                        va_bank: this.vaSelected,
                        expected_amount: (Number(this.getPrice()) * Number(this.payload.qty)) + Number(this.bookPayload.xendit_fee)
                    }
                    try {
                        this.isLoading = true
                        var responsePayment = await PaymentApi.postCreateVa('museum', param)
                        if(responsePayment.data && responsePayment.data.data) {
                            this.paymentData = responsePayment.data.data
                            this.page = 'payment'
                        }
                        setTimeout(() => {
                            this.isLoading = false
                        }, 500)
                    } catch (error) {
                        setTimeout(() => {
                            this.isLoading = false
                        }, 2000)
                        let message = " Mohon Maaf, terjadi kesalahan "
                        if(error.code) {
                            message += `([${error.code}] ${error.message}) `
                        }
                        if(error.response && error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message
                            if(typeof errorMessage === "string") {
                                message += ` = ${errorMessage} `
                            } else if(typeof errorMessage === "object") {
                                if(errorMessage.message) {
                                    message += ` = ${errorMessage.message}, Silahkan coba beberapa saat lagi!`
                                }
                            }
                        }
                        this.fireError(message)
                        console.log('error ->', error)
                    }
                    
                    break;
                default:
                    this.page = 'schedule'
                    break;
            }
        },
        doBack() {
            switch (this.page) {
                case 'schedule':
                    this.$router.back()
                    break;
                case 'list-guest':
                    this.page = "schedule"
                    break;
                case 'checkout':
                    this.bookPayload = null
                    this.page = "list-guest"
                    break;
                case 'payment':
                    this.$forceUpdate();
                    this.page = "schedule"
                    this.$router.push('/home')
                    break;
                default:
                    this.page = "schedule"
                    break;
            }
        },
        formatRupiah(angka, prefix){
			var number_string = angka.replace(/[^,\d]/g, '').toString(),
			split   		= number_string.split(','),
			sisa     		= split[0].length % 3,
			rupiah     		= split[0].substr(0, sisa),
			ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
            
			if(ribuan){
				var separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}
 
			rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix == undefined ? rupiah : (rupiah ? '' + rupiah : '');
		},
        
    }
    
})
</script>

<style scoped>
#container-museum-schedule {
    position: absolute;
    width: 100%;
    top: 20%;
    padding-left: 30px;
    padding-right: 30px;
    max-height: 500px;
    overflow: auto;
}

ion-input, ion-select {
    padding: 10.9432px 14.5909px !important;
    background: #FAFAFA;
    border: 0.911932px solid rgba(0, 0, 0, 0.6);
    border-radius: 7.29545px;
    --placeholder-color: rgb(87, 70, 70);
    --placeholder-opacity: 1;

}

ion-select {
    height: 63px;
}

.time-unselect {
    box-sizing: border-box;
    width: 68px;
    height: 49px;
    background: #FFFFFF;
    border: 1px solid #84C7C3;
    color: #222428;
    border-radius: 4px;
}

.time-selected {
    box-sizing: border-box;
    width: 68px;
    height: 49px;
    color: #e5e5e5;
    background: #326e48;
    border: 1px solid #84C7C3;
    border-radius: 4px;
}

ion-card {
    background: #f0ffff61;
}

.ats-select {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border-color: #84C7C3;
}

ion-col.text-end {
    text-align: end;
}

ion-col.text-center {
    text-align: center;
}
button.next-prev[disabled] {
    color: #c1c0c0 !important;
}


</style>
