
import { defineComponent } from 'vue';
import { IonRouterOutlet, IonPage } from '@ionic/vue';

export default defineComponent({
  name: 'AtsiriEatPage',
  components: {
      IonRouterOutlet,
      IonPage
  },
});
